.auth {
  width: 100%;
  max-width: 912px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 120px auto 0;
}

.auth_type_reminder {
  max-width: 976px;
}

/* Стили блока с картинкой */
.auth__image-block {
  width: 560px;
  height: 581px;
  position: relative;
}

.auth__curve {
  width: 372px;
  height: 563px;
  position: absolute;
  left: 179px;
  top: 8px;
}

.auth__welcome-block {
  width: 254px;
  height: 124px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 5px 58px rgba(0, 0, 0, 0.07);
  border-radius: 26px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 96px;
  padding-left: 30px;
  z-index: 1;
}

.auth__welcome-block_type_reminder {
  width: 320px;
}

.auth__welcome {
  max-width: 212px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
  margin: 0;
}

.auth__welcome_type_signin {
  font-size: 26px;
}

.auth__welcome_type_reminder {
  max-width: 272px;
}

.auth__triangle-icon {
  width: 43px;
  height: 76px;
  position: absolute;
  left: 176px;
  bottom: -45px;
  z-index: 2;
}

.auth__back-circle {
  width: 270px;
  height: 256px;
  background: #e5e5ff;
  border-radius: 50%;
  position: absolute;
  left: 50px;
  top: 219px;
  z-index: -1;
}

.auth__image {
  width: 286px;
  height: 284px;
  position: absolute;
  left: 136px;
  top: 220px;
  z-index: 0;
}

/* Стили формы */
.auth__form {
  width: 100%;
  max-width: 336px;
  background: #ffffff;
  box-shadow: 7px 4px 40px rgba(0, 0, 0, 0.04);
  border-radius: 32px;
  box-sizing: border-box;
  position: relative;
  margin-left: 16px;
  padding: 24px 32px;
}

.auth__form_type_reminder {
  max-width: 400px;
  margin-left: 4px;
}

.auth__title {
  font-family: 'KyivType Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #000000;
  margin: 0;
}

.auth__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #000000;
  margin: 0;
  padding-top: 12px;
}

.auth__input-box {
  display: grid;
  gap: 20px;
  padding-top: 28px;
}

.auth__input-box_type_signup {
  gap: 12px;
}

.auth__input-box_type_reminder {
  padding-bottom: 42px;
}

.auth__input-box_type_verification {
  padding-bottom: 42px;
  grid-template-columns: repeat(4, 53px);
  gap: 8px;
  justify-content: center;
}

.auth__label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #47464f;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.auth__label_type_agreement {
  padding-top: 8px;
}

/* Стили иконок инпутов */
.auth__input-icon-box {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  bottom: 16px;
}

.auth__input-icon-box_type_referral {
  bottom: 28px;
}

.auth__input-password-icon {
  width: 16px;
  height: 16px;
  background: transparent;
  border: none;
  position: absolute;
  right: 20px;
  bottom: 16px;
  cursor: pointer;
}

.auth__input-logo {
  width: 16px;
  height: 16px;
}

/* Стили инпутов */
.auth__input {
  height: 48px;
  box-sizing: border-box;
  background: #f7f7f7;
  border-radius: 40px;
  border: 1px solid transparent;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #313033;
  outline: transparent;
  padding: 16px 40px 16px 20px;
  margin-top: 4px;
}

.auth__input::placeholder {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #c8c5ca;
  margin: 0;
}

.auth__input:focus {
  border: 1px solid #787680;
}

.auth__input_type_referral {
  color: #47464f;
  background: transparent;
  border: 1px solid #e4e1ec;
  margin: 40px 0 12px;
  transition: border 0.2s ease;
}

.auth__input_type_referral::placeholder {
  color: #787680;
}

.auth__input_type_referral:hover {
  border: 1px solid #e5e1e6;
}

.auth__input_type_referral:focus {
  border: 1px solid #c8c5d0;
}

.auth__input_type_code {
  height: 68px;
  border-radius: 0;
  line-height: 120%;
  text-align: center;
  padding: 24px 18px;
  margin-top: 0;
}

.auth__input_type_error:focus {
  color: #ba1b1b;
  border: 1px solid #ba1b1b;
}

/* Стили сообщений об ошибке */
.auth__error {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  color: #ba1b1b;
  margin-top: 4px;
}

.auth__error_type_checkbox {
  margin: 0 0 4px;
}

.auth__api-error {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #ba1b1b;
}

/* Стили блока forgot password */
.auth__option-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 42px;
}

.auth__reminder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #242477;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.auth__reminder:hover {
  transform: scale(1.06);
}

/* Стили кнопки сабмита */
.auth__submit-button {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a2a3fd;
  border-radius: 30px;
  border: none;
  padding: 12px 72px;
  margin-top: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.auth__submit-button_type_phenom {
  padding: 0;
}
.auth__submit-button_disabled {
  background: #e1e0ff;
  pointer-events: none;
}

.auth__submit-button_loading {
  background: #a2a3fd;
  pointer-events: none;
}

.auth__submit-button:hover {
  background: #8788df;
}

.auth__submit-button:active {
  background: #c0c1ff;
}

.auth__submit-text {
  font-family: 'Jost', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding: 0 8px;
}
.auth__submit-text_type_phenom {
  font-size: 18px;
  font-weight: 500;
}
/* Стили кнопки логина через гугл */
.auth__google-signin {
  width: 100%;
  height: 48px;
  background: #cf4533;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 46px;
  margin-top: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.auth__google-signin_disabled {
  background: #d58277;
  pointer-events: none;
}

.auth__google-signin_loading {
  padding: 13px 26px;
  background: #cf4533;
  pointer-events: none;
}

.auth__google-signin:hover {
  background: #be3928;
}

.auth__google-signin:active {
  background: #da6657;
}

.auth__google-icon {
  width: 22px;
  height: 22px;
}

.auth__google-text {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

/* Стили редиректа на страницу регистрации/логина */
.auth__redirect-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin: 0;
}

.auth__redirect-button {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #ff8c59;
  margin: 0;
  padding-left: 4px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.auth__redirect-button:hover {
  transform: scale(1.06);
}

.auth__redirect-button:active {
  color: #ffb595;
}

/* Стили блока обратного отсчёта и повторной отправки верификационного кода */
.auth__countdown-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth__countdown {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  margin: 0;
}

.auth__countdown_hidden {
  display: none;
}

.auth__resend-code {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  background: transparent;
  border: none;
  margin-left: 16px;
  padding: 0;
  pointer-events: none;
}

.auth__resend-code_active {
  font-weight: 500;
  text-decoration-line: underline;
  margin-left: 0;
  pointer-events: all;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.auth__resend-code_active:hover {
  transform: scale(1.06);
}

/* Стили кнопки с крестиком */
.auth__close-button {
  width: 32px;
  height: 32px;
  background: transparent;
  border: 1px solid rgba(35, 35, 41, 0.2);
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -122px;
  top: -52px;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.auth__close-button:hover {
  transform: scale(1.3);
}

.auth__close-icon {
  width: 16px;
  height: 16px;
}
.auth__welcome-block_type_phenom {
  padding-left: 10px;
}
.auth__welcome_type_phenom {
  font-size: 20px;
  max-width: 254px;
}

@media (max-width: 1280px) {
  .auth {
    margin: 120px 64px 0;
  }

  .auth__image-block {
    width: 443px;
    height: 466px;
  }

  .auth__curve {
    width: 294px;
    height: 445px;
    left: 142px;
    top: 8px;
  }

  .auth__welcome-block {
    width: 202px;
    height: 98px;
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    top: 78px;
    padding-left: 24px;
  }

  .auth__welcome-block_type_reminder {
    width: 250px;
  }
  .auth__welcome {
    max-width: 169px;
    font-size: 19px;
  }

  .auth__welcome_type_signin {
    font-size: 21px;
  }

  .auth__welcome_type_reminder {
    max-width: 220px;
  }

  .auth__triangle-icon {
    width: 46px;
    height: 52px;
    left: 136px;
    bottom: -37px;
  }

  .auth__back-circle {
    width: 215px;
    height: 203px;
    left: 40px;
    top: 176px;
  }

  .auth__image {
    width: 230px;
    height: 228px;
    left: 108px;
    top: 176px;
  }

  .auth__close-button {
    right: -35px;
  }
  .auth__welcome_type_phenom {
    max-width: 254px;
  }
  .auth__welcome-block_type_phenom {
    width: 254px;
    font-size: 10px;
  }
}

@media (max-width: 910px) {
  .auth {
    margin: 120px 12px 0;
  }

  .auth__close-button {
    right: 0;
  }
}

@media (max-width: 800px) {
  .auth {
    justify-content: center;
  }

  .auth__image-block {
    display: none;
  }

  .auth__form {
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .auth__form {
    padding: 24px 12px;
  }

  .auth__submit-button_loading {
    padding: 12px 100px;
  }

  .auth__google-signin {
    padding: 13px 66px;
  }

  .auth__google-signin_loading {
    padding: 13px 42px;
  }
}
