.delete__modal_container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    height: 100%;
    max-height: 240px;
    background: #FFFFFF;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
h2 {
    font-weight: 600;
    font-family: "Jost", sans-serif;
}
.del__button {
    align-items: center;
    padding: 12px 40px;
    background: #A2A3FD;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Jost', sans-serif;
    border: none;
    color: #FFFFFF;
    width: 100%;
    max-width: 210px;
    height: 43px;
    line-height: 19px;
}
.cancel__button {
    color: #A2A3FD;
    background-color: #FFFFFF;
}
.button_group {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}
@media (max-width: 500px) {
    .delete__modal_container {
        max-width: 320px;
        text-align: center;
    }
    .button_group {
        display: block;
    }
}
