.system {
  max-width: 1440px;
  box-sizing: border-box;
  padding: 172px 70px 0;
  margin: 0 auto;
}

.system__title-box {
  display: flex;
  align-items: baseline;
}

.system__title {
  font-family: 'KyivType Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  color: #1b1b1e;
  margin: 0;
}

.system__more-link {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #ff8c59;
  margin: 0;
  padding-left: 18px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.system__more-link:hover {
  transform: scale(1.06);
}

.system__more-link:active {
  color: #ffb595;
}

.system__cards-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 60px 0 0;
}

.system__card {
  min-height: 326px;
  border: 1px solid #e1e0ff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 45px rgba(87, 88, 134, 0.04));
  border-radius: 16px;
  padding: 16px 16px 24px;
}

.system__icon {
  width: 44px;
  height: 44px;
}

.system__card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  color: #5455a9;
  margin: 0;
  padding-top: 40px;
}

.system__card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #5455a9;
  margin: 0;
  padding-top: 12px;
}

@media (max-width: 1280px) {
  .system {
    padding: 140px 40px 0;
  }

  .system__card {
    min-height: 410px;
  }
}

@media (max-width: 900px) {
  .system {
    text-align: center;
    padding: 136px 12px 0;
  }

  .system__title-box {
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
  }

  .system__more-link {
    padding: 16px 0 0;
  }

  .system__cards-list {
    grid-template-columns: repeat(auto-fit, minmax(min-content, 380px));
    justify-content: center;
  }

  .system__card-title {
    font-size: 26px;
  }
}
