.start {
  max-width: 978px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 140px;
}

.start__text-block {
  max-width: 452px;
  padding-right: 128px;
}

.start__title {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  color: #1b1b1e;
  margin: 0;
}

.start__text {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 120%;
  color: #1b1b1e;
  margin: 0;
  padding-top: 12px;
}

.start__link-buttons {
  display: grid;
  grid-template-columns: repeat(2, 220px);
  gap: 12px;
  padding-top: 40px;
}

.start__link {
  display: flex;
  align-items: center;
  border-radius: 12px;
  text-decoration: none;
  padding: 12px;
  cursor: pointer;
}

.start__link:hover .start__link-text {
  opacity: 1;
}

.start__link:active {
  opacity: 0.8;
}

.start__link_disabled {
  opacity: 0.6;
  pointer-events: none;
}

.start__link_type_tinkoff {
  background: #000000;
}

.start__link_type_ffin {
  background: #72bf44;
}

.start__icon-box {
  width: 47px;
  height: 47px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start__icon-box_type_tinkoff {
  background: #fff500;
}

.start__icon-box_type_ffin {
  background: #ffffff;
}

.start__text-box {
  padding-left: 8px;
}

.start__link-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  text-align: left;
  margin: 0;
}

.start__link-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  text-align: left;
  opacity: 0.7;
  text-decoration: underline;
  margin: 0;
  padding-top: 8px;
  transition: opacity 0.3s ease-in-out;
}

.start__signup-button {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  color: #ff8c59;
  border: 3px solid #ff8c59;
  box-sizing: border-box;
  border-radius: 45px;
  text-decoration: none;
  margin-top: 48px;
  padding: 16px 69px;
  cursor: pointer;
  transition: border-color 0.2s ease, color 0.2s ease;
}

.start__signup-button:hover {
  border-color: #de7342;
  color: #de7342;
}

.start__signup-button:active {
  border-color: #ffb595;
  color: #ffb595;
}

.start__image-block {
  width: 398px;
  height: 402px;
  position: relative;
  box-sizing: border-box;
  padding: 16px 24px;
}

.start__question-sign {
  width: 85px;
  height: 108px;
  position: absolute;
  left: 23px;
  top: 27px;
}

.start__image {
  width: 352px;
  height: 370px;
}

@media (max-width: 1280px) {
  .start {
    padding: 140px 40px 0;
  }

  .start__image-block {
    width: 305px;
    height: 309px;
    padding: 12px 18px;
  }

  .start__question-sign {
    width: 74px;
    height: 77px;
    left: 18px;
    top: 20px;
  }

  .start__image {
    width: 270px;
    height: 285px;
  }
}

@media (max-width: 950px) {
  .start {
    padding: 140px 12px 0;
  }
}

@media (max-width: 900px) {
  .start {
    flex-direction: column;
    text-align: center;
  }

  .start__text-block {
    padding-right: 0;
  }

  .start__title {
    font-size: 34px;
  }

  .start__text {
    font-size: 18px;
  }

  .start__signup-button {
    margin: 48px auto 0;
  }

  .start__image-block {
    width: 296px;
    padding: 0;
    margin-top: 128px;
  }

  .start__question-sign {
    width: 82px;
    height: 84px;
    left: 0px;
    top: 10px;
  }

  .start__image {
    width: 296px;
    height: 308px;
  }
}

@media (max-width: 560px) {
  .start__link-buttons {
    grid-template-columns: repeat(auto-fit, 220px);
    justify-content: center;
  }
}
