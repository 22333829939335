.questions {
  padding: 128px 112px 0;
}

.questions__title {
  font-family: 'KyivType Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #000000;
  margin: 0;
}

.questions__cards-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  list-style: none;
  margin: 0;
  padding: 86px 0 0;
}

.questions__card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  padding: 28px 36px 28px 28px;
}

.questions__text-box {
  flex: auto;
}

.questions__card-number {
  font-family: 'KyivType Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 120%;
  color: #a2a3fd;
  margin: 0;
}

.questions__card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  color: #a2a3fd;
  margin: 0;
  padding-top: 24px;
}

.questions__card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 120%;
  color: #0c0664;
  margin: 0;
  padding-top: 18px;
}

.questions__redirect {
  width: 156px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  background-color: #a2a3fd;
  border: none;
  text-decoration: none;
  border-radius: 100px;
  flex-shrink: 0;
  margin-top: 40px;
  padding: 14px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.questions__redirect:hover {
  background-color: #8788df;
}

.questions__redirect:active {
  background-color: #C0C1FF;
}

.questions__redirect_disabled {
  background-color: #E1E0FF;
  pointer-events: none;
}

.questions__redirect-box {
  flex-shrink: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  gap: 20px;
  margin-top: 40px;
}

.questions__link {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #000000;
  opacity: 0.4;
  text-decoration-line: underline;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.questions__link:hover {
  opacity: 0.8;
}

.questions__link:active {
  opacity: 0.6;
}

.questions__link_disabled {
  opacity: 0.2;
  pointer-events: none;
}

@media (max-width: 1280px) {
  .questions {
    padding: 128px 68px 0;
  }

  .questions__title {
    font-size: 28px;
  }

  .questions__cards-list {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }

  .questions__card {
    border-radius: 20px;
    padding: 16px 24px;
  }

  .questions__card-title {
    font-size: 20px;
  }

  .questions__card-text {
    font-size: 16px;
  }

  .questions__redirect-box {
    margin-top: 46px;
  }

  .questions__link {
    font-size: 16px;
  }
}

@media (max-width: 760px) {
  .questions {
    padding: 128px 12px 0;
  }

  .questions__card-title {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .questions__title {
    width: 70%;
    font-size: 24px;
    margin: 0 auto;
  }

  .questions__cards-list {
    padding: 32px 0 0;
  }

  .questions__card {
    text-align: center;
    align-items: center;
  }

  .questions__text-box {
    max-width: 208px;
  }
}
