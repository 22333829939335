.banner {
  margin: 0 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner__text-block {
  width: 42%;
  max-width: 500px;
}

.banner__title {
  font-family: 'KyivType Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 120%;
  color: #a2a3fd;
  margin: 0;
}

.banner__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #918f99;
  margin: 0;
  padding: 20px 24px 0 0;
}

.banner__start-button {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ff8c59;
  text-decoration: none;
  border-radius: 45px;
  padding: 14px 32px;
  margin-top: 60px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.banner__button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  margin: 0;
}

.banner__start-button:hover {
  background-color: #de7342;
}

.banner__image-block {
  width: 610px;
  height: 458px;
  position: relative;
}

.banner__back-circle {
  width: 400px;
  height: 400px;
  background: #fbea95;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 92px;
  z-index: -1;
}

.banner__girl-image {
  width: 292px;
  height: 428px;
  position: absolute;
  bottom: 0;
  left: 166px;
}

.banner__greeting-box {
  width: 262px;
  background: #ffffff;
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  box-sizing: border-box;
  position: absolute;
  top: 180px;
  left: 0px;
  padding: 20px;
}

.banner__triangle-icon {
  width: 55px;
  height: 46px;
  position: absolute;
  top: -11px;
  right: 18px;
}

.banner__greeting-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
  margin: 0;
}

.banner__greeting-text-semibold {
  font-weight: 600;
}

.banner__graph-box {
  border-radius: 5px;
  background: linear-gradient(
      0deg,
      rgba(84, 85, 169, 0.02),
      rgba(84, 85, 169, 0.02)
    ),
    #ffffff;
  box-shadow: -10px 4px 30px rgba(0, 0, 0, 0.06);
  position: absolute;
  bottom: 64px;
  right: 0;
  padding: 5px 0 5px 5px;
}

.banner__graph-title {
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 120%;
  color: #1b1b1e;
  margin: 0;
}

.banner__graph-image {
  width: 205px;
  height: 80px;
  padding-top: 9px;
}

@media (max-width: 1280px) {
  .banner__text-block {
    width: 49%;
    max-width: 435px;
  }

  .banner__title {
    font-size: 40px;
  }

  .banner__subtitle {
    font-size: 22px;
  }

  .banner__image-block {
    width: 429px;
    height: 342px;
  }

  .banner__back-circle {
    width: 298px;
    height: 298px;
    left: 39px;
  }

  .banner__girl-image {
    width: 218px;
    height: 320px;
    left: 95px;
  }

  .banner__greeting-box {
    width: 196px;
    box-shadow: 0px 3px 34px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    top: 135px;
    padding: 14px;
  }

  .banner__triangle-icon {
    width: 41px;
    height: 34px;
    top: -8px;
    right: 43px;
  }

  .banner__greeting-text {
    font-size: 14px;
  }

  .banner__graph-box {
    border-radius: 4px;
    bottom: 70px;
    padding: 4px 0 4px 4px;
  }

  .banner__graph-title {
    font-size: 6px;
  }

  .banner__graph-image {
    width: 136px;
    height: 56px;
    padding-top: 6px;
  }
}

@media (max-width: 1050px) {
  .banner {
    margin: 0 40px;
  }
}

@media (max-width: 760px) {
  .banner {
    width: calc(100% - 12px * 2);
    margin: 0 auto;
  }

  .banner__title {
    font-size: 28px;
  }

  .banner__subtitle {
    font-size: 18px;
  }

  .banner__start-button {
    margin-top: 48px;
  }

  .banner__image-block {
    width: 348px;
  }

  .banner__greeting-box {
    top: 140px;
  }

  .banner__graph-box {
    display: none;
  }
}

@media (max-width: 600px) {
  .banner {
    flex-direction: column;
    text-align: center;
  }

  .banner__text-block {
    width: 100%;
  }

  .banner__start-button {
    margin: 60px auto 0;
  }

  .banner__image-block {
    width: 310px;
    margin-top: 22px;
  }

  .banner__back-circle {
    left: 10px;
  }

  .banner__girl-image {
    left: 66px;
  }

  .banner__triangle-icon {
    right: 72px;
  }

  .banner__greeting-text {
    text-align: left;
  }
}
