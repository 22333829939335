.area {
    height: 100%;
    max-height: 600px;
}
.modal__info {
    width: 100%;
    position: absolute;
    background-color: rgba(0 , 0, 0, 0.5);
    top:0;
    left: 0;
    bottom: 63px;
    z-index: 2;
}
.info {
    width: 100%;
    max-width: 1200px;
    height: auto;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 12px;
    z-index: 3;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.close__button {
    float: right;
    cursor: pointer;
}
h3 {
    text-align: center;
    font-size: 32px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
}
p {
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 500;
}

@media (max-width: 1350px) {
    .info{
        max-width: 800px;
    }
}

@media (max-width: 1200px) {
    .info{
        max-width: 700px;
    }
}

@media (max-width: 1000px) {
    .info{
        max-width: 500px;
    }
}


@media (max-width: 800px) {
    .info{
        max-width: 400px;
    }
}

@media (max-width: 500px) {
    .info{
        max-width: 300px;
    }
}
