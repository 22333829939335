@import url(./assets/fonts/stylesheet.css);

body {
  margin: 0;
  font-family: 'Jost', 'KyivType Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  scrollbar-color: #458245 #714826;     /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: auto | thin | none;  /* толщина */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
  background-color: #fff;
  border-radius: 10px;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: rgba(84,85,169,1);
  border-radius: 10px;
  
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(84,85,169,1);
}

html[data-theme='dark'] {

}


html[data-theme='light'] {
 

}