.forgot-password {
  display: flex;
  align-items: center;
  justify-content: center;
}
.message {
  position: absolute;
  top: 200px;
  width: 680px;
  z-index: 100;
  border-radius: 30px;
  padding: 20px;
  left: calc(50vw - 340px);
  text-align: center;
  background-color: #fff;
  box-shadow: 7px 4px 40px rgba(0, 0, 0, 0.04);
  font: 600 25px/40px 'Jost', sans-serif;
}
@media screen and (max-width: 600px) {
  .message {
    width: 90%;
    top: 20%;
    left: 5%;
  }
}