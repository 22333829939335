.app {
    height: auto;
}
thead {
    position: sticky;
    top: 0;
    display: block;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
thead tr {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
table {
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.07);
    border-collapse: collapse;
    border-radius: 8px;
    box-sizing: border-box;
    border-spacing: 0;
}
.wrapper {
    padding: 30px 80px 0 0;
    height: auto;
    box-sizing: border-box;


}
button {
    cursor: pointer;
    transition: all 0.2s ease;
}
button:hover {
    opacity: 0.8;
    transform: scale(1.02);
}
input, select {
    outline: none;
}
.main_block {
    box-sizing: border-box;
    font-family: "Jost", sans-serif;
    min-height: calc(100vh - 80px);
    height: auto;
    width: calc(100vw - 188px);
    vertical-align: top;
    border-radius: 12px;
    padding: 16px;
    margin-left: 80px;
}

.main_block::before {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    margin: 10px 0 20px;
    text-align: left;
    display: block;
}

@media (max-width: 1200px) {
    .main_block {
        width: calc(100vw - 115px);
    }

    .wrapper {
        padding: 30px 0;
    }
}

@media (max-width: 600px) {
    .main_block {
        margin: 0 auto;
        width: 100%;

        padding: 5px;
    }

    .wrapper {
        width: 95%;
        margin: 0 auto;
        box-sizing: border-box;
    }
}

.main_block::before {
    font-size: 16px;
}

